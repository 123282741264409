<template>
    <div class="container">
        <Pbanner class="recuite__banners" v-if="recruitData.catinfo" :pbanner="recruitData.catinfo.thumb">
        <template #default>
            <div class="recuite__banner">
                <strong>国以才立，业以才兴，企以才强</strong>
                <p>“打造一流物流品牌，做供应链管理专家”</p>
            </div>
        </template>
        </Pbanner>
        <div class="leval-menu">
            <div class="leval-menu-wrap inner">
            <Levalmenu :menuNumber="menuNumber"/>
            <div class="posites">
                <span>所在位置：</span>
                <router-link to="/home">首页</router-link>
                <b>></b>
                <router-link to="/concept/concept">招聘信息</router-link>
                <b>></b>
                <router-link to="/concept/socialrecruit">社会招聘</router-link>
            </div>
            </div>  
        </div>
        <div class="socialrecruit">
            <div class="social__wrap inner">
                <div class="social__wrs">
                    <div class="social__search">
                        <i class="iconfont">&#xe610;</i>
                        <input type="text" @keydown.enter="slidelist" v-model="query.keywords" class="text" placeholder="搜索职位关键词"/>
                        <span class="search__btns" @click="slidelist">搜索职位</span>
                    </div>
                    <div class="login__bvts" >
                        <a v-if="token == '' || token == null || chekeToken == false" href="javascript:;" @click="logontarget"><span>立即登录</span><i></i></a>
                        <router-link v-else to="/personal"><span>个人中心</span><i></i></router-link>
                    </div>
                </div>
                
                <div class="all__posite" v-if="recruitData.pageinfo && recruitData.pageinfo.count">全部职位（共 <i>{{recruitData.pageinfo.count}}</i> 个）</div>
                <ul class="social__list">
                    <li class="slidetop" v-for="item in recruitData.list" :key="item.id" v-animate-onscroll="'active'">
                        <div class="social__info" @click="Popshow(item.id)">
                            <strong>{{item.title}}</strong>
                            <div class="social__tag">
                                <span>学历：{{item.degree}}</span>
                                <span>年龄：{{item.age}}</span>
                                <span>招聘人数：{{item.job_number}}人</span>
                                <span>工作地点：{{item.place}}</span>
                                <span>工作经验：{{item.experience}}</span>
                            </div>
                        </div>
                        <div class="social__time">
                            <a v-if="item.end_status == 1" href="javascript:;" @click="offPop(item.id)">填写简历</a>
                            <a v-else href="javascript:;">招聘截止</a>
                            <time>{{item.create_time}}   发布</time>
                        </div>
                    </li>
                </ul>
                <div class="user-pagination" >
                    <el-button type="button" @click="FirstPage" class="my-btn">首页</el-button>
                    <el-pagination ref="pagination"
                        background prev-text="上一页" next-text="下一页"
                        layout="prev, pager, next, slot"
                        @current-change="handleCurrentChange"
                        :current-page="query.page"
                        :total="total"
                        :page-size="pagesize"
                    >
                    </el-pagination>
                    <el-button type="button" @click="LastPage" class="my-btn" style="margin: 0px 5px">尾页</el-button>
                </div>
            </div>
        </div>
        <PopRecuite :popData='popData'/>
    </div>
</template>

<script>
import { useMyStore } from '../../store';
import Pbanner from '../../components/Pbanner';
import Levalmenu from '../../components/Levalmenu';
import PopRecuite from '../../components/common/PopRcuite.vue';
export default {
    data(){
        return {
            menuNumber: 4,
            recruitData: {},
            query:{
                catid: 15,
                pagesize: 5,
                keywords: "",
                page: 1
            },
            total: 0,
            pagesize: 5,
            popData: {},
            token: window.localStorage.getItem('token'),
            chekeToken: false
        }
    },
    components: {
        Pbanner,
        Levalmenu,
        PopRecuite
    },
    methods: {
        async getRecruit(){
            const {data: res} = await this.$http.get(`jobList?keywords=${this.query.keywords}&catid=${this.query.catid}&page=${this.query.page}`);
            if(res.status == 1){
                this.recruitData = res.data;
                this.total = Number(res.data.pageinfo.count);
            }else{
                this.$message.error('数据请求失败');
            }
            const {data: res1} = await this.$http.post('checkToken');
            if(res1.status == 1){
                this.chekeToken = true
            }else{
                this.chekeToken = false
            }
        },
        slidelist() {
            this.getRecruit()
        },
        async getRecruitPop(id){
            const {data: res} = await this.$http.get(`jobView?id=${id}`);
            if(res.status == 1){
                this.popData = res.data;
            }else{
                this.$message.error('数据请求失败');
            }
        },
        handleCurrentChange(newPage){
            this.query.page = newPage;
            this.getRecruit();
        },
        FirstPage(){
            this.$refs.pagination.handleCurrentChange(1);
            this.$emit('handleCurrentChange', 1);
        },
        LastPage(){
            let font = this.$refs.pagination
            let cpage = Math.ceil(font.total / font.pageSize);
            font.handleCurrentChange(cpage);
        },
        Popshow(id) {
            useMyStore().setPoprecuiteShow()
            this.getRecruitPop(id);
            $('#recuite__pops').animate({
                scrollTop: 0
            }, 0);
        },
        logontarget() {
            this.$router.push('/login')
        },
        offPop(id) {
            if(this.chekeToken){
                this.$router.push(`/biographical/${id}`)
            }else{
                this.$router.push('/login')
            }
        }
    },
    mounted() {
        this.getRecruit();
    }
}
</script>

<style lang="less">
   
</style>