<template>
    <div class="container">
         <Pbanner v-if="styleData.catinfo" :pbanner="styleData.catinfo.thumb"/>
         <div class="leval-menu">
            <div class="leval-menu-wrap inner">
            <Levalmenu :menuNumber="menuNumber"/>
            <div class="posites">
                <span>所在位置：</span>
                <router-link to="/home">首页</router-link>
                <b>></b>
                <router-link to="/concept/concept">招聘信息</router-link>
                <b>></b>
                <router-link to="/concept/style">员工风采</router-link>
            </div>
            </div>  
        </div>
        <div class="staff">
            <div class="staff-wrap">
                 <swiper class="staff-swiper" :options="swiperOption" ref="mySwiper" v-if='styleData.list'>
                    <swiper-slide class="staff-item" v-for="item in styleData.list" :key="item.cid">
                        <figure>
                            <img :src="item.thumb" :alt="item.title"/>
                        </figure>
                        <div class="staff-text">
                            <p>{{item.title}}</p>
                        </div>
                    </swiper-slide>
                    
                </swiper>
                <div class="swiper-pagination" slot="pagination"></div>
            </div>
        </div>
    </div>
</template>

<script>
import Pbanner from '../../components/Pbanner';
import Levalmenu from '../../components/Levalmenu';
export default {
    data(){
        return {
            menuNumber: 4,
            styleData: [],
            swiperOption: {
                loop: true,
                speed:1000,
                centeredSlides: true,
                slidesPerView: 2,
                slideToClickedSlide: true,
                autoplay: {
                    delay: 4000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                observeParents:false,   
                observer:true,
                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                },
                breakpoints: {
                    1024: {
                        slidesPerView: 1,
                    }
                }
            }
        }
    },
    components: {
        Pbanner,
        Levalmenu,
    },
    methods: {
        async getImages(){
            const {data: res} = await this.$http.get("getImages");
            
            if(res.status == 1){
                this.styleData = res.data;
            }else{
                this.$message.error('数据请求失败');
            }
        },
    },
    mounted() {
        this.getImages();
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper;
           
        },
    }
}
</script>

<style lang="less">
    .staff{
        padding: 100px 10.41% 70px;
        background: #f6f5fa;
    }
    .staff-item{
        position: relative;
        padding: 100px 0;
        &.swiper-slide-active{
            z-index: 3;
            figure{
                transform: scale(1.4);
                border-width: 2px;
                &:before{
                    opacity: 0;
                    visibility: hidden;
                }
            }
            .staff-text{
                opacity: 1;
                visibility: visible;
                transition: 0.5s 0.35s;
            }
        }
        figure{
            display: block;
            position: relative;
            z-index: 1;
            line-height: 1;
            width: 100%;
            padding-bottom: 55.85%;
            box-sizing: border-box;
            border: 4px solid #fff;
            transform: scale(0.8);
            transition: 0.65s;
            &:before{
                position: absolute;
                left: 0;
                top: 0;
                z-index: 2;
                content: "";
                width: 100%;
                height: 100%;
                background: rgba(0,0,0,0.5);
                transition: 0.5s;
            }
            img{
                display: block;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }
    .staff-text{
        position: relative;
        padding-top: 44px;
        margin-top: 80px;
        transition: 0.2s;
        opacity: 0;
        visibility: hidden;
        &:before{
            position: absolute;
            left: 0;
            top: 0;
            content: "";
            width: 100%;
            height: 57px;
            background: url(../../assets/staffbg.png) center top no-repeat;
            background-size: 100% auto;
        }
        p{
            text-align: center;
            color: #333333;
            font-size: 16px;
        }
    }
    .staff-wrap{
        position: relative;
        .swiper-pagination{
            position: absolute;
            left: 0;
            bottom: 60px;
            width: 100%;
            display: flex;
            justify-content: center;
            .swiper-pagination-bullet{
                margin-right: 24px;
                width: 10px;
                height: 10px;
                opacity: 1;
                background: #999999;
                transition: 0.4s;
                &.swiper-pagination-bullet-active{
                    background: #c4261d;
                }
                &:last-child{
                    margin-right: 0;
                }
            }
        }
    }
</style>